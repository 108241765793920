.row {
  padding: 100px 0 50px 0;
  max-width: 1200px;
}

.text_div {
  padding-right: 10%;
  position: relative;
  top: 100%;
  transform: translateY(-100%);
}

.text_header {
  font-size: 40px;
  font-weight: 700;
}

.text_content {
  font-size: 24px;
}

.image_div {
  width: 80%;
  margin-left: auto;
}

.text_modal_header {
  font-size: 40px;
  font-weight: 700;
}

.text_modal_header::after {
  content: "";
  display: block;
  height: 4px;
  width: 130px;
  background-color: var(--primaryitemcolor);
}

.image {
  width: 100%;
}

.about_modal {
  padding: 30px;
}

.about_body {
  overflow-y: auto;
  max-height: 300px;
  margin-top: 20px;
}

.button_modal_div {
  text-align: right;
  margin-top: 20px;
}

@media only screen and (max-width: 991px) {
  .text_div {
    padding-right: 0;
    position: static;
    transform: translateY(0);
  }

  .image_div {
    width: 70%;
    margin: auto;
    margin-top: 30px;
  }

  .text_header,
  .text_modal_header {
    font-size: 36px;
  }

  .text_content {
    font-size: 20px;
  }

  .button_modal_div {
    margin-top: 10px;
  }

  .about_modal {
    padding: 20px;
  }
}

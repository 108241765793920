.row {
  padding: 100px 0 50px 0;
  max-width: 1200px;
}

.header_div {
  text-align: center;
}

.header_div h2 {
  font-size: 40px;
  font-weight: 700;
  color: var(--primaryitemcolor);
}

.header_div p {
  color: var(--textcolor);
  font-size: 20px;
}

.row_dish {
  border-bottom: 1px solid var(--bordercolor);
}

.dish_content {
  padding: 0 30px;
}

.dish_col {
  padding: 30px 0;
  border: 1px solid var(--bordercolor);
}

@media only screen and (max-width: 991px) {
  .row_dish {
    border-bottom: none;
  }

  .dish_col {
    border: none;
    border-bottom: 1px solid var(--bordercolor);
    padding: 30px 0;
  }
}

@media only screen and (max-width: 576px) {
  .header_div h2 {
    font-size: 36px;
  }

  .header_div p {
    font-size: 16px;
  }
}

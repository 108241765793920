.tooltip {
  position: fixed;
  z-index: 1100;
  width: 200px;
  background-color: var(--primaryitemcolor);
  left: 50%;
  top: 15%;
  transform: translateX(-50%);
  text-align: center;
  color: var(--light);
  padding: 8px 0;
  border-radius: 5px;
  opacity: 0;
  display: none;
}

@media only screen and (max-width: 1200px) {
  .tooltip.show {
    display: block;
    animation: showtooltip 1s ease-out 0s 1 normal;
  }

  @keyframes showtooltip {
    20% {
      opacity: 1;
    }
    70% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}
